import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { trackEvent } from '../../services/analytics';
import callApi from '../../services/api';

import Home from '../../components/Home/Home';
import Modal from '../../components/Modal';
import { Icon } from '../../components/Home/homeStyledComponents';
import { mobileThresholdPixels, colors, fontSizes, Header2, Title2, Title3, Highlight }
  from '../../components/styledComponents';
import android from '../../assets/android.svg';
import iOS from '../../assets/iOS.svg';
import closeImage from '../../assets/close.png';
import backgroundImage from '../../assets/photo-header.jpg';

const P = styled.p`
  color: ${colors.navy};
  text-align: justify;
  padding: 20px;
  font-weight: 300;
  margin: 20px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 10px 0px;
    text-align: justify;
    font-size: 12px;
  }
`;

const Text = styled.p`
  color: ${colors.navy};
  text-align: center;
  padding: 0px 20px;
  font-weight: 400;
  margin: 20px 0px 0px;
  font-size: ${fontSizes.m};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    font-size: ${fontSizes.s};
  }
`;

const Row = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const CloseContainer = styled.div`
  position: absolute;
  top: 8px;
  right: 10px;
  ${'' /* height: 15px;
  width: 15px; */}
`;

const Close = styled.img`
  height: 15px;
  width: 15px;
  object-fit: cover;
`;

const hasClickedInsideModale = (evt) => {
  const flyoutElement = document.getElementById('modal-retouche');
  let targetElement = evt.target; // clicked element

  do {
    if (targetElement === flyoutElement) {
      return true;
    }
    // Go up the DOM
    targetElement = targetElement.parentNode;
  } while (targetElement);

  return false;
};

class InvitePage extends React.Component {
  constructor() {
    super();
    this.state = { showModal: true };
  }

  componentDidMount() {
    const { location: { search } } = this.props;
    if (search) {
      this.fetchReferrer(search.slice(3));
    }
  }

  fetchReferrer(referrerCode) {
    callApi(`public/referrer/${referrerCode}`).then(({ referrer }) => {
      this.props.dispatch({ type: 'ORDER.SET_REFERRER', referrer });
    });
  }

  renderCloseModal() {
    return (
      <CloseContainer onClick={() => this.setState({ showModal: false })}>
        <Close src={closeImage} alt="fermer" />
      </CloseContainer>
    );
  }

  renderModal() {
    const { referrer } = this.props;
    return (
      <Modal show={this.state.showModal} backgroundImage={backgroundImage}>
        <div id="modal-retouche">
          {this.renderCloseModal()}
          <Header2>
            <Title3>
              {referrer && referrer.firstname &&
                <div>
                  <Highlight>{referrer.firstname}</Highlight>
                  {' t’envoie une bonne intention'}
                </div>
              }
            </Title3>
          </Header2>

          <P>
            Un jean troué ou trop long, une robe fétiche qui a besoin d’être revisitée,
            ou qui a besoin d’un nouveau souffle…<br />
            Les aiguilles de nos tillistes, tous couturiers de métier, seront des alliées
            parfaites pour retoucher, réajuster ou transformer votre garde-robe.
          </P>

          <Title2>
            {referrer && referrer.referrerCode &&
              <div>
                <Highlight>5€ offerts</Highlight>
                {' avec le code '}
                <Highlight>{referrer.referrerCode}</Highlight>
              </div>
            }
          </Title2>

          <Text>
            Notre app :
          </Text>

          <Row>
            <a
              target="_blank" rel="noreferrer noopener"
              href="https://play.google.com/store/apps/details?id=fr.tilli.app"
              onClick={() => trackEvent('click', 'store_android_invite')}
            >
              <Icon src={android} alt="application Android" />
            </a>
            <a
              target="_blank" rel="noreferrer noopener"
              href="https://itunes.apple.com/us/app/tilli/id1280578121"
              onClick={() => trackEvent('click', 'store_iOS_invite')}
            >
              <Icon src={iOS} alt="application iPhone" />
            </a>
          </Row>
        </div>
      </Modal>
    );
  }

  render() {
    const props = this.props;
    return (
      <div // eslint-disable-line jsx-a11y/no-static-element-interactions
        onClick={e => !hasClickedInsideModale(e) && this.setState({ showModal: false })}
      >
        {this.renderModal()}
        <Home {...props} />
      </div>
    );
  }
}

InvitePage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  referrer: PropTypes.shape({}),
};

InvitePage.defaultProps = {
  referrer: null,
};

// const mapStateToProps = state => ({
//   referrer: state.order.referrer,
// });
// const mapDispatchToProps = dispatch => ({ dispatch });
// export default connect(mapStateToProps, mapDispatchToProps)(InvitePage);
export default InvitePage;
